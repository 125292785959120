<template>
  <div>
    <create-album v-if="mode === 'new'" />
    <update-album v-else />
  </div>
</template>
<script>
import CreateAlbum from './CreateAlbum.vue'
import UpdateAlbum from './UpdateAlbum.vue'

export default {
  name: 'AlbumPage',
  components: {
    CreateAlbum,
    UpdateAlbum,
  },
  data() {
    return {
      mode: null,
    }
  },
  created() {
    if (this.$route.params.id === 'new') {
      this.mode = 'new'
    } else {
      this.mode = this.$route.params.id
    }
  },
}
</script>
