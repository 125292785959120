<template>
  <div>
    <h4 class="img-uploader-title">
      {{ title }}
    </h4>
    <b-form-file
      v-model="localFiles"
      placeholder="Выберите файл"
      drop-placeholder="Drop file here..."
      multiple
    />
    <div
      v-for="localFile in localFiles"
      :key="localFile.id"
      class="my-1"
    >
      Выбранный файл: <strong>{{ localFile ? localFile.name : '' }}</strong>
      <b-spinner
        v-if="localFile.name === loadingFile.name"
        class="spinner"
        small
      />
      <BIconArrowClockwise
        v-if="errorsFiles.filter(file => file.name === localFile.name).length > 0 && localFile.name !== loadingFile.name"
        class="arrow-icon"
        small
        @click="uploadFile(localFile)"
      />
      <arrow-done
        :local-file="localFile"
        :is-loaded-file="isLoadedFile"
      />
    </div>
    <div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-button"
        :disabled="isloading"
        @click="uploadFiles"
      >
        <b-spinner
          v-if="isloading"
          small
        />
        {{ isloading ? 'Загрузка...' : 'Загрузить' }}
      </b-button>
      <!-- <b-button
        v-if="serverFile"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"

        class="mr-1"
        @click="openFile"
      >
        Посмотреть файл
      </b-button> -->
      <!-- <b-button
        v-if="serverFile"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1"
        @click="deleteFile"
      >
        Удалить файл
      </b-button> -->
    </div>

  </div>
</template>

<script>
import {
  BButton,
  BFormFile,
  BSpinner,
  BIconArrowClockwise,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import config from '@core/app-config/api-config'
import axios from 'axios'
import ArrowDone from './ArrowDone.vue'

export default {
  name: 'FileUploader',
  components: {
    BFormFile,
    BButton,
    BSpinner,
    ArrowDone,
    BIconArrowClockwise,
  },
  directives: {
    Ripple,
  },
  props: {
    title: String,
    value: Number,
    default: Object,
  },
  data() {
    return {
      isloading: false,
      localFiles: null,
      serverFile: null,
      isLoaded: false,
      isLoadedFile: {},
      loadingFile: {},
      errorsFiles: [],
    }
  },
  computed: {
  },
  watch: {
    default() {
      this.serverFile = this.default
    },
    localFiles() {
      this.$emit('checkNewPhotos', this.localFiles)
    },
  },
  created() {
  },
  methods: {
    loadedFile(data) {
      this.localFiles.filter(file => {
        if (file.name === data.name) {
          this.isLoadedFile = file
        }
        return file
      })
    },
    deleteFile() {
      this.serverFile = null
      this.$emit('input', null)
    },
    delay() {
      return new Promise(resolve => setTimeout(resolve, 2000))
    },
    async uploadFiles() {
      // eslint-disable-next-line no-restricted-syntax
      for (const localFile of this.localFiles) {
        if (!localFile) {
          this.makeToast('danger', 'Ошибка загрузки', 'Ошибка')
          return false
        }
        this.isloading = true
        this.isLoaded = false
        const formData = new FormData()
        formData.append('file', localFile)
        formData.append('lastModified', localFile.lastModified)
        this.loadingFile = localFile

        // eslint-disable-next-line no-await-in-loop

        // eslint-disable-next-line no-await-in-loop

        await axios
          .post(`${config.host}/file/upload`, formData)
          .then(r => {
            this.serverFile = r.data
            this.$emit('input', this.serverFile.id)
            this.makeToast('success', `Файл успешно загружен ${r.data.name}`, 'Успешно')
            this.errorsFiles = this.errorsFiles.filter(file => file.name !== localFile.name)
            this.isloading = false
            this.loadingFile = {}
            this.loadedFile(localFile)
            this.$emit('updatePhotos', this.serverFile)
          })
          .catch(error => {
            this.makeToast('danger', 'Ошибка загрузки файла на сервер', 'Ошибка')
            this.errorsFiles.push(localFile)
            this.isloading = false
            this.loadingFile = {}
          })
      }
    },
    async uploadFile(localFile) {
      if (!localFile) {
        this.makeToast('danger', 'Ошибка загрузки', 'Ошибка')
        return false
      }
      this.isloading = true
      this.isLoaded = false
      const formData = new FormData()
      formData.append('file', localFile)
      formData.append('lastModified', localFile.lastModified)
      this.loadingFile = localFile

      // eslint-disable-next-line no-await-in-loop

      // eslint-disable-next-line no-await-in-loop
      await axios
        .post(`${config.host}/file/upload`, formData)
        .then(r => {
          this.serverFile = r.data
          this.$emit('input', this.serverFile.id)
          this.makeToast('success', `Файл успешно загружен ${r.data.name}`, 'Успешно')
          this.errorsFiles = this.errorsFiles.filter(file => file.name !== localFile.name)
          this.isloading = false
          this.loadingFile = {}
          this.loadedFile(localFile)
          this.$emit('updatePhotos', this.serverFile)
        })
        .catch(error => {
          this.makeToast('danger', 'Ошибка загрузки файла на сервер', 'Ошибка')
          this.errorsFiles.push(localFile)
          console.log(error)
          this.isloading = false
          this.loadingFile = {}
        })
    },
    makeToast(variant = null, message = null, title = null) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
      })
    },
    openFile() {
      window.open(`${this.serverFile.path}/${this.serverFile.name}`, '_blank')
    },
  },
}
</script>

<style scoped lang="scss">
  .save-button{
    margin-top: 20px;
  }

  .spinner {
    margin-left: 5px;
  }

  .arrow-icon {
    margin-left: 5px;
    cursor: pointer;
  }
</style>
