<template>
  <check-icon
    v-show="isShow"
    size="1.5x"
    class="custom-class"
  />
</template>
<script>
import { CheckIcon } from 'vue-feather-icons'

export default {
  components: {
    CheckIcon,
  },
  props: ['localFile', 'isLoadedFile'],
  data() {
    return {
      isShow: false,
    }
  },
  watch: {
    isLoadedFile() {
      this.checkIsLoaded()
    },
  },
  methods: {
    checkIsLoaded() {
      if (this.localFile.name === this.isLoadedFile.name) {
        this.isShow = true
        return true
      }
      return false
    },
  },
}
</script>
